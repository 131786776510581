<template>
    <!-- Page Wrapper -->
    <div class="wrapper admin">
        <!-- Preloader -->
        <!-- <div class="preloader flex-column justify-content-center align-items-center">
            <img class="animation__wobble" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
        </div> -->
        <slide></slide>
        <nav-ad></nav-ad>
        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <!-- /.col -->
                        <div class="col-sm-8">
                            <!-- <ol class="breadcrumb float-sm-left">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Dashboard <el-icon><ArrowDownBold /></el-icon></li>
                            </ol> -->
                            <bread-crumb></bread-crumb>
                        </div>
                        <div class="icon-question col-sm-4 text-right">
                            Hướng dẫn sử dụng <i class="fas fa-question-circle" @click="openHelp"></i>
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>

                <!-- <nav-tab></nav-tab> -->
                <!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->

            <!-- Main content -->
            <section class="content">
                <router-view></router-view>
            </section>
            <!-- /.content -->
        </div>
        <aside class="control-sidebar control-sidebar-dark">
        </aside>
        <footer-ad></footer-ad>
    </div>
</template>
<style scoped>
@import "../../assets/css/sb-admin.css";
@import "../../assets/css/admin.css";
@import "../../assets/css/admin.min.css";
</style>
<script>
import slideBar from '@/components/common/Sidebar'
import navAdmin from '@/components/common/Nav'
import footerAdmin from '@/components/common/Footer'
import breadcrumb from '@/components/common/Breadcrumb'
// import navTab from '@/components/common/navTab/NavTab'
export default {
    components: {
        'slide': slideBar,
        'nav-ad': navAdmin,
        'footer-ad': footerAdmin,
        'bread-crumb': breadcrumb,
        // 'nav-tab': navTab
    },
    create() {
        window.location.reload();
    },
    methods: {
        openHelp() {
            this.$router.push('/huongdan')
        },
        reloadPage() {
             window.location.reload();
        }
    }
    // methods: {
    //     loadScripAdminLTE(){
    //         // return new Promise(resolve => {
    //         //     let scriptEl = document.createElement("script");
    //         //     scriptEl.src = "assets/js/plugin/adminlte.js";
    //         //     scriptEl.type = "text/javascript";

    //         //     // Attach script to head
    //         //     document.getElementsByTagName("head")[0].appendChild(scriptEl); 
    //         //     // Wait for tag to load before promise is resolved     
    //         //     scriptEl.addEventListener('load',() => {
    //         //         resolve();
    //         //     });
    //         // });
    //         let adminlte = document.createElement('script')
    //         adminlte.setAttribute('src', 'assets/js/plugin/adminlte.js')
    //         adminlte.async = true;
    //         document.head.appendChild(adminlte)
    //         let menu = document.createElement('script')
    //         menu.setAttribute('src', 'assets/js/plugin/menu.js')
    //         menu.async = true;
    //         document.head.appendChild(menu)
    //         let dashboard = document.createElement('script')
    //         dashboard.setAttribute('src', 'assets/js/plugin/dashboard.js')
    //         dashboard.async = true;
    //         document.head.appendChild(dashboard)
    //     }
    // },
    // create() {
    //     this.loadScripts().then(() => {
    //        this.executeScript();
    //     });
    // },
    //   executeScript() {
    //     // remove script tags
    //     let script = string.replace(/<\/?script>/g, "")
    //     eval(script)
    //   }
    
}
</script>

