export const VEW_SERVICE_GET_ALL = '/view/room-services/list/'
export const VEW_REPORT_ROOM_CONTRACT = '/view/report-room-contract/'
export const VEW_SERVICE_GET_BY_SERVICE_ID = '/view/room-services/list/'
export const VEW_ROOM_SERVICE_DETAIL = '/view/room-service-detail/list/'
export const ROOM_SERVICE_DETAIL_BY_ROOM = '/view/room-service-detail-by-room/list/'
export const VEW_TABLE_ROWS = '/view/table-row'
export const VEW_TABLE_ROWS_BUILD = '/view/table-build/'
export const VEW_TECHNICAL = '/view/technical-build/'
export const VEW_BUILD_SHARE = '/view/build-share'
export const VEW_RECEIPT_REPORT = '/view/view-receipt-report/'
export const VEW_TABLE_ROWS_BUILD_SERVICE = '/view/table-build-service/'
export const VEW_DEPOSIT_REPORT = '/view/view-deposit-report/'