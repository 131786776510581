export default {
    'setCallAPI'(context, countryChange) {
        context.commit('apiCall', countryChange)
    },
    'setCountryChange'(context, countryChange) {
        context.commit('countryChange', countryChange)
    },
    'setHistoryBack'(context, isHistoryBack) {
        context.commit('historyBack', isHistoryBack)
    },
    'setBreadcrumb'(context, isbreadcrumb) {
        context.commit('breadcrumb', isbreadcrumb)
    },
    'toggleMenuSidebar'(context) {
        context.commit('toggleMenuSidebar');
    },
    'toggleControlSidebar'(context) {
        context.commit('toggleControlSidebar');
    },
    'toggleResetSeting'(context) {
        context.commit('toggleResetSeting');
    },
    'toggleDarkMode'(context) {
        context.commit('toggleDarkMode');
    },
    // 'removeDarkMode'(context) {
    //     context.commit('removeDarkMode');
    // },
    'toggleNavbarFixed'(context) {
        context.commit('toggleNavbarFixed');
    },
    'toggleNoBorder'(context) {
        context.commit('toggleNoBorder');
    },
    'setNavbarVariant'(context, value) {
        context.commit('setNavbarVariant', value);
    },
    'setSidebarSkin' (context, value) {
        context.commit('setSidebarSkin', value);
    },
    'setActiveTab' (context, value) {
        context.commit('setActiveTab', value);
    },
    'setNotReadMsg' (context, value) {
        context.commit('setNotReadMsg', value);
    },
    'setReceiptId' (context, value) {
        context.commit('setReceiptId', value);
    },
    'setIsVip' (context, value) {
        context.commit('setIsVip', value);
    },
    'setIsCheckR' (context, value) {
        context.commit('setIsCheckR', value);
    },
    'setMenuChange' (context, value) {
        context.commit('setMenuChange', value);
    },
    'setStoreCategory' (context, value) {
        context.commit('setStoreCategory', value);
    },
    'setLoadCart' (context, value) {
        context.commit('setLoadCart', value);
    },
    'setLoadLogin' (context, value) {
        context.commit('setLoadLogin', value);
    },
    'setSearchKey' (context, value) {
        context.commit('setSearchKey', value);
    },
    'setCheckLogin' (context, value) {
        context.commit('setCheckLogin', value);
    },
};
