import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import routesAdmin from "./admin";
import store from '../store'
import { useCookies } from "vue3-cookies"
import { SETTING_BREAKCRUMB, USER_LOGINED } from '@/const/cookie'
const host = window.location.hostname;
const DOMAIN_ADMIN = process.env.VUE_APP_DOMAIN_ADMIN;
const { cookies } = useCookies()
let routes: Array<RouteRecordRaw> = [];

routes = routesAdmin;
store.dispatch('setHistoryBack', '11111111111')
store.dispatch('setHistoryBack', '222222222222')
store.dispatch('setHistoryBack', '3333333333')
store.dispatch('setHistoryBack', '55555555555555')
const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  let isLoginer = cookies.get(USER_LOGINED)
  // let isLoginer = store.getters.isLoggedIn
  store.dispatch('setBreadcrumb', to.meta.breadcrumb)
  cookies.set(SETTING_BREAKCRUMB, JSON.stringify(to.meta.breadcrumb));
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isLoginer === 'true') {
      next()
      return
    } else {
      next('/')
    }
  } else {
    next()
  }
});
export default router;
