import Index from '../views/dg6789admin6868/Index.vue'
const routesExample =   {
    path: '/home2',
    name: 'home2',
    component: Index,
    meta: {
      requiresAuth: true,
      breadcrumb: [{'/admin': 'Home'}]
    }
    ,
    children: [

       {
        path: '/form-input',
        name: 'form input',
        component: () =>
          import('../views/example/form/Input.vue'),
        meta: {
          requiresUnauth: true,
          breadcrumb: [{'/admin': 'Home', '/form': 'form'}]
        }
      }, {
        path: '/button',
        name: 'button',
        component: () =>
          import('../views/example/form/Button.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/admin': 'Home', '/': 'hop dong bao hiem'}]
        }
      }, {
        path: '/pushmenu',
        name: 'pushmenu',
        component: () =>
          import('../views/example/pushmenu/PushMenu.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/admin': 'Home', '/': 'hop dong bao hiem'}]
        }
      }, {
        path: '/pdf',
        name: 'pdf',
        component: () =>
          import('../views/example/pdf/View.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/admin': 'Home', '/': 'Hướng dẫn'}]
        }
      }, {
        path: '/sign',
        name: 'sign',
        component: () =>
          import('../views/example/pdf/sign/SignDemo.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/admin': 'Home', '/': 'Hướng dẫn'}]
        }
      }, {
        path: '/uploadFile',
        name: 'uploadFile',
        component: () =>
          import('../views/example/upload/Upload.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/admin': 'Home', '/': 'Hướng dẫn'}]
        }
      }, {
        path: '/book',
        name: 'book',
        component: () =>
          import('../views/example/book/OpenBook.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/admin': 'Home', '/': 'Hướng dẫn'}]
        }
      }, {
        path: '/tablescoll',
        name: 'tablescoll',
        component: () =>
          import('../views/example/tableScoll/TableRowColor.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client'}]
        }
      }, {
        path: '/excelview',
        name: 'excelview',
        component: () =>
          import('../views/example/excel/ExcelView.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client'}]
        }
      }, {
        path: '/doc-iframe',
        name: 'doc-iframe',
        component: () =>
          import('../views/example/doc/Iframe.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client'}]
        }
      }, {
        path: '/doc-vue',
        name: 'doc-vue',
        component: () =>
          import('../views/example/doc/VueDocPreview.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client'}]
        }
      }, {
        path: '/slider-tab',
        name: 'slider-tab',
        component: () =>
          import('../views/example/tab/SliderTab.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client'}]
        }
      }, {
        path: '/download',
        name: 'download',
        component: () =>
          import('../views/example/download/Download.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client'}]
        }
      }, {
        path: '/example-import',
        name: 'example-import',
        component: () =>
          import('../views/example/import/Import.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client'}]
        }
      }, {
        path: '/transfer',
        name: 'transfer',
        component: () =>
          import('../views/example/transfer/Transfer.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client'}]
        }
      }, {
        path: '/tittle',
        name: 'tittle',
        component: () =>
          import('../views/example/form/TittleDropdow.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client'}]
        }
      }, {
        path: '/autoCreate',
        name: 'autoCreate',
        component: () =>
          import('../views/example/AutoCreateForm.vue'),
        meta: {
          requiresAuth: true,
          breadcrumb: [{'/home': 'Home', '/user': 'Người sử dung', '': 'Danh sách client'}]
        }
      }]
  }

export default routesExample